import Axios from "axios";
import { HOST } from "../config";

async function UserInfoService() {
  const UserInfoResponse = await Axios.get(`${HOST}/v2/users/me`);
  const UserInfo = UserInfoResponse.data;
  return UserInfo;
}

export { UserInfoService };
