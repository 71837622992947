import { useStoreActions, useStoreState } from "easy-peasy";

export default function useResetCode() {
  const sendResetCode = useStoreActions((state) => state.login.sendResetCode);
  const setForgotPassword = useStoreActions(
    (state) => state.login.setForgotPassword
  );
  const invalidResetEmail = useStoreState(
    (state) => state.login.invalidResetEmail
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    sendResetCode({ email });
  };

  return {
    handleSubmit,
    invalidResetEmail,
    setForgotPassword,
  };
}
