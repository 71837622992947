import { makeStyles } from "@material-ui/core";
import React from "react";
import AddDevice from "./AddDevice";
import DeviceTotals from "./DeviceTotals";

const useStyles = makeStyles((theme) => ({
  userInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "6em",
  },
}));

export default function UserDeviceInfo() {
  const classes = useStyles();
  return (
    <div className={`${classes.userInfo}`}>
      <DeviceTotals />
      <AddDevice />
    </div>
  );
}
