import { makeStyles } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useInput } from "../../../hooks/useInput";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "rgb(227, 83, 31)",
    color: "#fff",
  },
  signIn: {
    color: "rgb(227, 83, 31)",
  },
}));

function useSignIn() {
  const classes = useStyles();
  const authenticate = useStoreActions((state) => state.login.authenticate);
  const isInvalidForm = useStoreState((state) => state.login.isInvalidForm);
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const setIsRegistering = useStoreActions(
    (state) => state.login.setIsRegistering
  );
  const setForgotPassword = useStoreActions(
    (state) => state.login.setForgotPassword
  );
  const handleSubmit = (evt) => {
    evt.preventDefault();
    authenticate({
      username: email,
      password: password,
    });
  };
  useEffect(() => {
    return () => {
      // console.log("Sign in unmount");
    };
  }, []);
  return {
    classes,
    authenticate,
    isInvalidForm,
    setIsRegistering,
    email,
    bindEmail,
    password,
    bindPassword,
    handleSubmit,
    setForgotPassword,
  };
}
export { useSignIn };
