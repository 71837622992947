import { useStoreState } from "easy-peasy";
import React from "react";
import { UpdateDeviceService } from "../../api/UpdateDeviceService";
import useInterval from "./hooks/useInterval";

export default function UpdateTable({ value, tableMeta, updateValue, id }) {
  const guid = tableMeta.rowData[1];
  const token = useStoreState((state) => state.login.token);
  useInterval(() => {
    // console.log(`creating interval for ${tableMeta.rowData[0]}`);
    asyncUpdateDevice({
      guid,
      value,
      updateValue,
      token,
    });
  }, 20000);

  return (
    <div style={{ position: "relative" }}>
      <span id={id}>{value}</span>
    </div>
  );
}

async function asyncUpdateDevice({ guid, value, updateValue, token }) {
  const deviceInfo = await UpdateDeviceService({ guid, token });
  const { DeviceMessageCount } = deviceInfo;

  if (deviceInfo)
    localStorage.setItem(`${guid}_updated_info`, JSON.stringify(deviceInfo));
  if (value !== DeviceMessageCount) {
    console.log(`Device Message Count Update ${guid}`);
    updateValue(DeviceMessageCount);
  }
}
