import { makeStyles } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "rgb(227, 83, 31)",
    color: "#fff",
  },
  signUp: {
    color: "rgb(227, 83, 31)",
  },
}));

export const useSignUp = () => {
  const classes = useStyles();
  const signUp = useStoreActions((state) => state.login.signUp);
  const signUpErr = useStoreState((state) => state.login.signUpErr);
  const setIsRegistering = useStoreActions(
    (state) => state.login.setIsRegistering
  );
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { firstName, lastName, email, password } = evt.target;
    const signUpObj = {
      Email: email.value,
      FirstName: firstName.value,
      LastName: lastName.value,
      NameSpace: `${firstName.value}_${lastName.value}`,
      Password: password.value,
    };
    signUp({ signUpObj });
  };
  return {
    classes,
    signUp,
    signUpErr,
    setIsRegistering,
    handleSubmit,
    handleClickShowPassword,
    showPassword,
  };
};
