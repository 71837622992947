import { makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React from "react";
import { CSSTransition } from "react-transition-group";
import Spinner from "../Spinners/spinner";
import CurrentDevice from "./CurrentDevice";
import { useGetDevices } from "./hooks/useGetDevices";

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    opacity: (props) => (props.isLoading ? 0.5 : 1),
    marginTop: "1em",
    paddingLeft: "3em",
    paddingRight: "3em",
  },
}));

export default function Table(props) {
  const {
    userDevices,
    username,
    isLoading,
    options,
    cols,
    currentDevice,
    setCurrentDevice,
  } = useGetDevices();
  const classes = useStyles({ isLoading });
  return (
    <div className={`${classes.loadingOverlay}`}>
      {isLoading ? (
        <>
          <Spinner className={"device_spinner"} />

          <MUIDataTable
            title={`Loading Device Console...`}
            options={{
              textLabels: {
                body: {
                  noMatch: "",
                },
              },
            }}
          />
        </>
      ) : null}

      {currentDevice ? (
        <CSSTransition
          in={currentDevice ? true : false}
          key={"current_device"}
          appear={true}
          timeout={300}
          classNames="fade"
        >
          <CurrentDevice
            currentDevice={currentDevice}
            setCurrentDevice={setCurrentDevice}
          />
        </CSSTransition>
      ) : null}

      <CSSTransition
        in={!isLoading && !currentDevice ? true : false}
        key={"device_table"}
        appear={true}
        timeout={300}
        classNames="fade"
      >
        <MUIDataTable
          title={`Device Console: ${username}`}
          data={userDevices.length > 0 ? userDevices : []}
          columns={cols ? cols : []}
          options={options}
        />
      </CSSTransition>
    </div>
  );
}
