import { FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSignIn } from "./hooks/useSignIn";

export default function SignIn() {
  const {
    classes,
    isInvalidForm,
    setIsRegistering,
    bindEmail,
    bindPassword,
    handleSubmit,
    setForgotPassword,
  } = useSignIn();

  return (
    <div>
      <Typography variant="h5" className={`${classes.signIn}`}>
        Sign in
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          {...bindEmail}
          error={isInvalidForm}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          error={isInvalidForm}
          {...bindPassword}
        />
        {isInvalidForm ? (
          <FormHelperText error>
            <svg
              aria-hidden="true"
              fill="currentColor"
              focusable="false"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
            </svg>{" "}
            Invalid email or password
          </FormHelperText>
        ) : null}

        {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
        >
          Sign In
        </Button>
        <Grid container style={{ cursor: "pointer" }}>
          <Grid item xs>
            <Link
              onClick={(e) => {
                setForgotPassword(true);
              }}
              variant="body2"
            >
              {"Forgot password?"}
            </Link>
          </Grid>
          <Grid item xs>
            <Link
              onClick={(e) => {
                setIsRegistering(true);
              }}
              variant="body2"
            >
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
