import { useStoreState } from "easy-peasy";
import React from "react";
import { Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../Sidebar";

export default function AdminPage(props) {
  const token = useStoreState((state) => state.login.token);

  return (
    <div>
      {!token ? (
        <Redirect to="/admin/login" />
      ) : (
        <CSSTransition
          in={token ? true : false}
          key={"signin"}
          appear={true}
          timeout={300}
          classNames="fade"
        >
          <Sidebar />
        </CSSTransition>
      )}
    </div>
  );
}
