import { Box, Card, makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import logo from "../assets/images/infiswift-fabric.svg";
import Login from "../components/Login";
import Spinner from "../components/Spinners/spinner";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://infiswift.tech/">
        Infiswift Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  loginGrid: {
    display: "grid",
    gridTemplateAreas: "main",
    gridTemplateColumns: "450px",
    justifyContent: "center",
    opacity: (props) => (props.isLoading ? 0.5 : 1),
  },
  loginLogo: {
    width: "100%",
  },
}));

export default function LoginPage() {
  const token = useStoreState((state) => state.login.token);
  const isLoading = useStoreState((state) => state.login.isLoading);
  const classes = useStyles({ isLoading });
  const checkIfTokenExists = useStoreActions(
    (state) => state.login.checkIfTokenExists
  );

  useEffect(() => {
    checkIfTokenExists();
    return () => {};
    //eslint-disable-next-line
  }, []);
  return (
    <div className={`${classes.loginGrid}`}>
      {isLoading ? <Spinner className={"login_spinner"} /> : null}
      <Card id="login-main" elevation={3}>
        <img
          src={logo}
          alt="adminportal-logo"
          className={`${classes.loginLogo}`}
        />
        {token && localStorage.getItem("token") ? (
          <Redirect to="/admin/deviceconsole" />
        ) : null}
        <Login />
        <Box mt="4em">
          <Copyright />
        </Box>
      </Card>
    </div>
  );
}
