import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { useInput } from "../../../hooks/useInput";

export default function useUpdatePassword() {
  const username = useStoreState((state) => state.login.username);
  const invalidCode = useStoreState((state) => state.login.invalidCode);
  const getUpdatePassword = useStoreActions(
    (state) => state.login.getUpdatePassword
  );

  const setForgotPassword = useStoreActions(
    (state) => state.login.setForgotPassword
  );
  const { bind: bindPassword } = useInput("");
  const { bind: bindCode } = useInput("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, code } = e.target;
    getUpdatePassword({
      email: email.value,
      password: password.value,
      code: code.value,
    });
  };
  return {
    username,
    invalidCode,
    bindPassword,
    bindCode,
    handleClickShowPassword,
    handleSubmit,
    showPassword,
    setForgotPassword,
  };
}
