import { Tooltip } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import CopyText from "../CopyText";
import UpdateStatus from "../UpdateStatus";
import UpdateTable from "../UpdateTable";

export function useGetDevices() {
  const userDevices = useStoreState((state) => state.devices.userDevices);

  const username = useStoreState((state) => state.login.username);
  const isLoading = useStoreState((state) => state.devices.isLoading);
  const token = useStoreState((state) => state.login.token);
  const getUserDevices = useStoreActions(
    (state) => state.devices.getUserDevices
  );

  const [cols, setCols] = useState(null);
  const [options, setOpts] = useState({});

  const [currentDevice, setCurrentDevice] = useState(null);
  const deleteUserDevices = useStoreActions(
    (state) => state.devices.deleteUserDevices
  );
  const initialCols = [
    {
      name: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip title="click to update" aria-label="click to update">
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                const { rowData, rowIndex } = tableMeta;
                setCurrentDevice({ rowData, rowIndex, updateValue });
              }}
            >
              <span id={`deviceName_${tableMeta.rowIndex}`}>{value}</span>
            </div>
          </Tooltip>
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={0} onClick={() => updateDirection(0)}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Device Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <CopyText value={value} id={`deviceId_${tableMeta.rowIndex}`} />
          </div>
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={1} onClick={() => updateDirection(1)}>
            {columnMeta.name}
          </th>
        ),
      },
    },

    {
      name: "Username",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <CopyText value={value} id={`username_${tableMeta.rowIndex}`} />
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)}>
            {columnMeta.name}
          </th>
        ),
      },
    },

    {
      name: "Password",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <CopyText value={value} id={`password_${tableMeta.rowIndex}`} />
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={3} onClick={() => updateDirection(3)}>
            {columnMeta.name}
          </th>
        ),
      },
    },

    {
      name: "Last Connected",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <CopyText
            value={!value || value.length === 0 ? "never connected" : value}
            id={`lastConnected_${tableMeta.rowIndex}`}
          />
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={4} onClick={() => updateDirection(4)}>
            <span>{columnMeta.name}</span>
          </th>
        ),
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ position: "relative", bottom: 5 }}>
              <UpdateStatus
                value={value}
                tableMeta={tableMeta}
                updateValue={updateValue}
                id={`deviceStatus_${tableMeta.rowIndex}`}
              />
            </div>
          );
        },
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={5} onClick={() => updateDirection(5)}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Messages",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <UpdateTable
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            id={`deviceMessages_${tableMeta.rowIndex}`}
          />
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={6} onClick={() => updateDirection(6)}>
            {columnMeta.name}
          </th>
        ),
      },
    },
  ];

  const getOpts = () => {
    const initialOpts = {
      onTableChange: async (action, tableState) => {
        if (tableState.data.length > 0) {
          // const interval = setInterval(async () => {
          //   const data = await getUpdate({ currentData: tableState.data });
          //   tableState.data = data;
          // }, 5000);
          // console.log(tableState);
        }
      },
      onTableInit: (action, tableState) => {
        // console.log({ action, tableState });
      },
      setRowProps: (row) => {
        // console.log({ row });
      },
      onRowsDelete: (rowsDeleted) => {
        // return false;
        const { data } = rowsDeleted;
        const index = data[0].index;
        const guid = userDevices[index][0];
        deleteUserDevices({ guid });
      },

      filter: false,
      selectableRows: false, // <===== will turn off checkboxes in rows
      responsive: "stacked",
      resizableColumns: true,
    };
    return initialOpts;
  };

  useEffect(() => {
    setCols(initialCols);
    const initialOpts = getOpts();
    setOpts(initialOpts);

    callGetUserDevices({ token });
    async function callGetUserDevices({ token }) {
      await getUserDevices({ token });
    }

    return () => {
      // console.log("Table unmount");
    };

    //eslint-disable-next-line
  }, []);

  return {
    userDevices,
    username,
    isLoading,
    deleteUserDevices,
    options,
    cols,
    currentDevice,
    setCurrentDevice,
  };
}
