import {
  Card,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { blue, green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import useAddDevice from "./hooks/useAddDevice";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    fontSize: "1.2em",
    color: "#fff",
    fontWeight: 600,
  },
  infoIcon: {
    color: blue[400],
    marginLeft: "3px",
  },
  addCircle: {
    fontSize: "1em",
    color: (props) => (props.isLoading ? "grey" : green[400]),
  },
}));

export default function AddDevice() {
  const {
    open,
    handleClickOpen,
    bindName,
    handleCancel,
    handleClose,
    handleSubmit,
    isLoading,
  } = useAddDevice();
  const classes = useStyles({ isLoading });
  return (
    <div className={`${classes.flexContainer}`}>
      <Card>
        <IconButton
          onClick={handleClickOpen}
          children={
            <div>
              <Typography variant="h6">Add Device</Typography>
              <AddCircleIcon className={`${classes.addCircle}`} />
            </div>
          }
        />
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Create new device
            <Tooltip
              placement="top"
              title={
                <span className={`${classes.label}`}>
                  Label the device for your identification.
                </span>
              }
            >
              <InfoIcon fontSize="small" className={`${classes.infoIcon}`} />
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              id="device_name"
              label="Name"
              name="name"
              {...bindName}
              // error={isInvalidForm}
              autoComplete="name"
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
