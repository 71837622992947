import Axios from "axios";
import { HOST } from "../config";

async function LoginService({ username, password }) {
  const GetLoginResponse = await Axios.post(`${HOST}/login`, {
    username: username,
    password: password,
  });
  const loginData = GetLoginResponse.data;
  return loginData;
}
export { LoginService };
