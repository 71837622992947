import Axios from "axios";
import { HOST } from "../config";

async function SignUpService({ signUpObj }) {
  const SignUpResponse = await Axios.post(`${HOST}/v2/users`, signUpObj)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.status;
    });
  const data = SignUpResponse;
  return data;
}

export { SignUpService };
