import { makeStyles, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { useInput } from "../../hooks/useInput";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1em",
  },
  saveInput: {
    backgroundColor: "rgb(102, 187, 106)",
    padding: "1em",
    borderRadius: "5px",
    border: "none",
    fontSize: ".9em",
    fontWeight: "600",
    color: "#fff",
    cursor: "pointer",
  },
  goBackInput: {
    marginRight: "4em",
    backgroundColor: "rgb(0, 0, 0, 0.5)",
    padding: "1em",
    borderRadius: "5px",
    border: "none",
    fontSize: ".9em",
    fontWeight: "600",
    color: "#fff",
    cursor: "pointer",
  },
}));

export default function CurrentDevice({ currentDevice, setCurrentDevice }) {
  const { rowData, rowIndex, updateValue } = currentDevice;
  const classes = useStyles();
  const goBack = (e) => {
    e.preventDefault();
    setCurrentDevice(null);
  };
  const { bind: bindName } = useInput(rowData[0]);
  const userAssets = useStoreState((state) => state.devices.userAssets);
  const token = useStoreState((state) => state.login.token);
  const changeDeviceInfo = useStoreActions(
    (state) => state.devices.changeDeviceInfo
  );

  const [editMode, setEditMode] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const newName = e.target.name.value;

    const currentDeviceInfo = userAssets.find(
      (obj) => obj.AssetName === rowData[0]
    );

    changeDeviceInfo({
      newName,
      currentDeviceInfo,
      rowIndex,
      updateValue,
      setCurrentDevice,
      token,
    });
    setEditMode(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setEditMode(true);
    // sendResetCode({ email });
  };

  return (
    <div className={`${classes.container}`}>
      <div style={{ width: "600px" }}>
        <Typography variant="h6">Device Information</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={editMode === false}
            label="Name"
            size="small"
            name="name"
            {...bindName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClick}>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={`${classes.flexContainer}`}>
            <input
              onClick={goBack}
              value="Back"
              type="button"
              className={`${classes.goBackInput}`}
            />
            <input
              value="Save"
              className={`${classes.saveInput}`}
              disabled={editMode === false}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
