import Axios from "axios";
import { HOST } from "../config";

async function CreateAssetService({ name, token }) {
  const CreateAssetResponse = await Axios.post(`${HOST}/v2/assets`, {
    AssetName: name,
  });
  const assetData = CreateAssetResponse.data;
  return assetData;
}
async function DeleteAssetService({ guid }) {
  const DeleteAssetResponse = await Axios.delete(
    `${HOST}/v2/assets/${guid}`
  );
  const deleteRes = DeleteAssetResponse.data;
  return deleteRes;
}
export { CreateAssetService, DeleteAssetService };

