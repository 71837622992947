import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import { useInput } from "../../../hooks/useInput";

export default function useAddDevice() {
  const [open, setOpen] = React.useState(false);
  const token = useStoreState((state) => state.login.token);
  const createAssetDevice = useStoreActions(
    (state) => state.devices.createAssetDevice
  );
  const isLoading = useStoreState((state) => state.devices.isLoading);
  const handleClickOpen = () => {
    if (!isLoading) setOpen(true);
  };
  const { bind: bindName } = useInput("");
  const handleCancel = () => {
    setOpen(false);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    createAssetDevice({ name, token });
    setOpen(false);
  };
  return {
    open,
    createAssetDevice,
    handleClickOpen,
    bindName,
    handleCancel,
    handleClose,
    handleSubmit,
    isLoading,
  };
}
