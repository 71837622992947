import Axios from "axios";
import { HOST } from "../config";

async function UpdateDeviceService({ guid }) {
  const UpdateDeviceResponse = await Axios.get(`${HOST}/v2/assets/${guid}`);
  const { data } = UpdateDeviceResponse;
  return data;
}

export { UpdateDeviceService };
