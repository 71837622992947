import React from "react";
import SnackbarMsg from "../SnackbarMsg";
import UserDeviceInfo from "../UserDeviceInfo";
import Table from "./Table";

export default function DeviceConsole() {
  return (
    <div>
      <UserDeviceInfo />
      <SnackbarMsg />
      <Table />
    </div>
  );
}
