import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Menu from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useStoreActions } from "easy-peasy";
import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import logo from "./assets/images/infiswift-fabric.svg";
import routes from "./routes.js";
const drawerWidth = 240;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/deviceconsole" />
  </Switch>
);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#f3f3f3",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9) + 1,
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    backgroundColor: "#f3f3f3",
  },
  content: {
    flex: 1,
    paddingLeft: "6em",
  },
  footer: {
    width: "100%",
    borderTop: ".2px solid rgb(0,0,0,0.5)",
    textAlign: "center",
    padding: "30px",
    flexShrink: 0,
    left: "0",
    bottom: "0",
    zIndex: 999,
    height: "50px",
  },
  flexFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logOut: {
    position: "absolute",
    right: 0,
    marginRight: "1em",
  },
}));

export default function Sidebar() {
  // console.log("Drawer render");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const logOut = useStoreActions((state) => state.login.logOut);
  const resetDevices = useStoreActions((state) => state.devices.resetDevices);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          {/* <Typography variant="h5">Infiswift Fabric Admin Portal</Typography> */}
          <a href="https://www.infiswift.tech/">
            <img src={logo} alt="adminportal-logo" width="250px" />
          </a>
          <div className={`${classes.logOut}`}>
            <IconButton
              onClick={(e) => {
                logOut();
                resetDevices();
              }}
            >
              <NavLink style={{ color: "#999" }} to="/login">
                <Typography variant="h6">Log Out</Typography>
              </NavLink>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <Typography variant="subtitle2" noWrap>
            Infiswift Technologies
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ArrowBackIosIcon />
          </IconButton>
        </div>
        <Divider />
        <List
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {routes.map((route, index) => (
            <NavLink to={route.layout + route.path} key={index}>
              {/* {console.log(route)} */}
              <ListItem button key={route.name}>
                <ListItemIcon>
                  <route.icon
                    style={{
                      fontSize: "24px",
                      color: "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    fontSize: "24px",
                    color: "#fff",
                  }}
                  primary={route.name}
                />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>

      <div className={`${classes.content}`}>{switchRoutes}</div>

      <div className={`${classes.footer}`}>
        <div className={`${classes.flexFooter}`}>
          <Link
            style={{
              fontSize: "1em",
              paddingRight: "1em",
              color: "rgb(0,0,0,0.8)",
            }}
            href="https://www.infiswift.tech"
          >
            &copy; {1900 + new Date().getYear()} Infiswift Technologies Inc
          </Link>

          <Link
            style={{
              fontSize: "1em",
              paddingRight: "1em",
              color: "rgb(0,0,0,0.8)",
            }}
            href="https://www.infiswift.tech/privacy-policy/"
          >
            Privacy policy
          </Link>

          <Link
            style={{
              fontSize: "1em",
              paddingRight: "1em",
              color: "rgb(0,0,0,0.8)",
            }}
            href="https://www.infiswift.tech/terms-and-conditions/"
          >
            Terms of use
          </Link>
        </div>
      </div>
    </div>
  );
}
