import Switch from "@material-ui/core/Switch";
import React from "react";
import useInterval from "./hooks/useInterval";

function SwitchLabels({ deviceStatus, id }) {
  const [state] = React.useState({
    checkedA: true,
    checkedB: false,
  });

  return (
    <Switch
      checked={deviceStatus ? state.checkedA : state.checkedB}
      name="checkedA"
      id={id}
      style={{
        color: deviceStatus ? "rgb(153, 255, 153)" : "rgb(255, 204, 203)",
      }}
      readOnly
    />
  );
}

export default function UpdateStatus({ value, tableMeta, updateValue, id }) {
  const guid = tableMeta.rowData[1];

  useInterval(() => {
    if (localStorage.getItem(`${guid}_updated_info`)) {
      const {
        DeviceStatus,
        // Guid
      } = JSON.parse(localStorage.getItem(`${guid}_updated_info`));
      if (DeviceStatus !== value) {
        // Check to make sure Guid's are matching
        // console.log({
        //   Guid,
        //   guid,
        //   updatedData: localStorage.getItem(`${guid}_updated_info`),
        // });
        console.log(
          `UPDATE DEVICE ${tableMeta.rowData[0]} STATUS = ${DeviceStatus}`
        );
        updateValue(DeviceStatus);
        localStorage.removeItem(`${guid}_updated_info`);
      }
    }
  }, 22000);

  return <SwitchLabels deviceStatus={value} id={id} />;
}
