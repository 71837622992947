import Axios from "axios";
import { HOST } from "../config";

async function UpdateAssetName({
  AssetClassGuid,
  AssetName,
  Namespace,
  ParentAssetGuid,
  Pin,
  Guid,
  token,
}) {
  // Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const UpdateAssetNameRes = await Axios.put(`${HOST}/v2/assets/${Guid}`, {
    AssetClassGuid,
    AssetName,
    Namespace,
    ParentAssetGuid,
    Pin,
  });

  const { data, status } = UpdateAssetNameRes;
  return { data, status };
}

export { UpdateAssetName };
