import axios from "axios";
import { action, thunk } from "easy-peasy";
import {
  CreateAssetService,
  DeleteAssetService,
  UpdateAssetName,
  UserAssetService,
  UserInfoService,
} from "../api";

const devices = {
  snackBarMsg: { bool: false, message: "", variant: "success" },
  setSnackBarMsg: action((state, { bool, message, variant }) => {
    state.snackBarMsg = { bool, message, variant };
  }),
  deleteUserDevices: thunk(async (actions, { guid }) => {
    actions.setIsLoading(true);
    await DeleteAssetService({ guid });

    actions.deleteDevice({ guid });
    actions.setIsLoading(false);
    actions.setSnackBarMsg({
      bool: true,
      message: `Successfully deleted device with Guid: ${guid}`,
      variant: "warning",
    });
  }),
  deleteDevice: action((state, { guid }) => {
    const userDevices = [];
    state.userDevices.forEach((row) => {
      if (row[0] !== guid) {
        userDevices.push(row);
      }
    });
    state.userDevices = userDevices;
  }),
  createAssetDevice: thunk(async (actions, { name, token }) => {
    actions.setIsLoading(true);
    const newDevice = await CreateAssetService({ name, token });
    if (newDevice && !newDevice.AssetExists) {
      actions.setNewDevice(newDevice);
      actions.setIsLoading(false);
      actions.setSnackBarMsg({
        bool: true,
        message: `Successfully created device with Guid: ${newDevice.Guid}`,
        variant: "success",
      });
    } else if (newDevice && newDevice.AssetExists) {
      actions.setIsLoading(false);
      actions.setSnackBarMsg({
        bool: true,
        message: `Duplicate device with Name: ${newDevice.AssetName}`,
        variant: "warning",
      });
    } else if (Object.values(newDevice).length === 0) {
      actions.setIsLoading(false);
      actions.setSnackBarMsg({
        bool: true,
        message: `Duplicate device with Name: ${newDevice.AssetName}`,
        variant: "warning",
      });
    }
  }),
  setNewDevice: action((state, newDevice) => {
    const {
      AssetName,
      Guid,
      DeviceUserName,
      DevicePassword,
      DeviceStatus,
      DeviceMessageCount,
      DeviceLastConnectedDate,
    } = newDevice;
    state.userDevices.push([
      AssetName,
      Guid,
      DeviceUserName,
      DevicePassword,
      DeviceLastConnectedDate,
      DeviceStatus,
      DeviceMessageCount,
    ]);
  }),
  isLoading: false,
  setIsLoading: action((state, bool) => {
    state.isLoading = bool;
  }),
  intervals: [],
  pushInterval: action((state, { interval }) => {
    // console.log({ interval });
    state.intervals.push(interval);
  }),
  userDevices: [],
  setFetchedDevices: action((state, { alldevices }) => {
    state.userDevices = alldevices;
    return state;
  }),
  getUserDevices: thunk(async (actions) => {
    try {
      actions.setIsLoading(true);
      const userAssets = await UserAssetService();
      actions.setUserAssets({ userAssets });
      const alldevices = [];

      userAssets.sort((a, b) => (a.DeviceStatus > b.DeviceStatus ? -1 : 1));
      userAssets.forEach((el) => {
        const {
          AssetName,
          Guid,
          DeviceUserName,
          DevicePassword,
          DeviceLastConnectedDate,
          DeviceStatus,
          DeviceMessageCount,
        } = el;
        alldevices.push([
          AssetName,
          Guid,
          DeviceUserName,
          DevicePassword,
          DeviceLastConnectedDate,
          DeviceStatus,
          DeviceMessageCount,
        ]);
      });
      actions.setFetchedDevices({
        alldevices: alldevices,
      });
      actions.setIsLoading(false);
    } catch (err) {
      console.log(err);
      actions.setIsLoading(false);
    }
  }),
  userAssets: [],
  setUserAssets: action((state, { userAssets }) => {
    state.userAssets = userAssets;
  }),
  totalDevices: 0,
  connectedDevices: 0,
  totalMessages: 0,
  getUserInfo: thunk(async (actions, { authInfo }) => {
    const auth = JSON.parse(authInfo);
    const { token } = auth;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const UserInfo = await UserInfoService();
    if (Object.values(UserInfo).length > 0) {
      const {
        UserActiveDeviceCount,
        UserDeviceMessageCount,
        UserDeviceCount,
      } = UserInfo;
      actions.setUserInfo({
        UserActiveDeviceCount,
        UserDeviceMessageCount,
        UserDeviceCount,
      });
    }
  }),
  setUserInfo: action(
    (
      state,
      { UserActiveDeviceCount, UserDeviceMessageCount, UserDeviceCount }
    ) => {
      state.totalDevices = UserDeviceCount;
      state.connectedDevices = UserActiveDeviceCount;
      state.totalMessages = UserDeviceMessageCount;
    }
  ),
  resetDevices: action((state) => {
    state.userDevices = [];
    state.totalDevices = [];
    state.connectedDevices = [];
    state.totalMessages = [];
  }),
  changeDeviceInfo: thunk(
    async (
      actions,
      {
        newName,
        currentDeviceInfo,
        rowIndex,
        updateValue,
        setCurrentDevice,
        token,
      }
    ) => {
      const {
        AssetClassGuid,
        Namespace,
        ParentAssetGuid,
        Pin,
        Guid,
      } = currentDeviceInfo;

      const newDeviceInfo = await UpdateAssetName({
        AssetClassGuid,
        AssetName: newName,
        Namespace,
        ParentAssetGuid,
        Pin,
        Guid,
        token,
      });
      const { status } = newDeviceInfo;
      if (status === 200) {
        actions.setNewInfo({ Guid, rowIndex, newName });
        actions.setSnackBarMsg({
          bool: true,
          message: `Successfully updated name to ${newName}`,
          variant: "success",
        });
        updateValue(newName);
      } else {
        actions.setSnackBarMsg({
          bool: true,
          message: `Unsuccessfully updated name to ${newName}`,
          variant: "error",
        });
      }
      setCurrentDevice(null);
    }
  ),
  setNewInfo: action((state, { Guid, rowIndex, newName }) => {
    state.userDevices[rowIndex][0] = newName;
    state.userAssets.forEach((asset) => {
      if (asset.Guid === Guid) {
        asset.AssetName = newName;
      }
    });
  }),

  isUpdating: false,
  setIsUpdating: action((state, bool) => {
    state.isUpdating = bool;
  }),
};

export default devices;
