import { Card, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CSSTransition } from "react-transition-group";
import Spinner from "../Spinners/spinner";
import useDeviceTotals from "./hooks/useDeviceTotals";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  cardStyle: {
    marginLeft: "1em",
    marginRight: "1em",
    padding: "1em",
    position: "relative",
  },
}));

export default function DeviceTotals() {
  const {
    totalDevices,
    connectedDevices,
    totalMessages,
    isLoading,
  } = useDeviceTotals();
  const classes = useStyles({ isLoading });
  return (
    <div className={`${classes.flexContainer}`}>
      <Card className={`${classes.cardStyle}`}>
        <Typography variant="h6">Total Devices</Typography>

        <Typography variant="h6">
          {isLoading ? (
            <Spinner className="total_device_spinner" />
          ) : (
            <CSSTransition
              in={!isLoading}
              key={"total_connected"}
              appear={true}
              timeout={300}
              classNames="fade"
            >
              <span>{totalDevices}</span>
            </CSSTransition>
          )}
        </Typography>
      </Card>
      <Card className={`${classes.cardStyle}`}>
        <Typography variant="h6">Connected Devices</Typography>
        <Typography variant="h6">
          {isLoading ? (
            <Spinner className="total_device_spinner" />
          ) : (
            <CSSTransition
              in={!isLoading}
              key={"total_connected"}
              appear={true}
              timeout={300}
              classNames="fade"
            >
              <span>{connectedDevices}</span>
            </CSSTransition>
          )}
        </Typography>
      </Card>
      <Card className={`${classes.cardStyle}`}>
        <Typography variant="h6">Total Messages</Typography>
        <Typography variant="h6">
          {isLoading ? (
            <Spinner className="total_device_spinner" />
          ) : (
            <CSSTransition
              in={!isLoading}
              key={"total_connected"}
              appear={true}
              timeout={300}
              classNames="fade"
            >
              <span>{totalMessages}</span>
            </CSSTransition>
          )}
        </Typography>
      </Card>
    </div>
  );
}
