import { Button, makeStyles, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useStoreActions } from "easy-peasy";
import React from "react";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  signUp: {
    color: "rgb(227, 83, 31)",
  },
  checkCircleStyle: {
    color: "green",
    fontSize: "4em",
    padding: "1em",
  },
  activationFont: {
    color: "#999",
  },
  signIn: {
    margin: "2em",
    backgroundColor: "rgb(227, 83, 31)",
    color: "#fff",
  },
}));

export default function Activation() {
  const setIsRegistering = useStoreActions(
    (state) => state.login.setIsRegistering
  );
  const signUpSuccess = useStoreActions((state) => state.login.signUpSuccess);
  const classes = useStyles();
  return (
    <div className={`${classes.flexContainer}`}>
      <Typography variant="h5" className={`${classes.signUp}`}>
        Sign-Up Success
      </Typography>
      <CheckCircleOutlineIcon className={`${classes.checkCircleStyle}`} />
      <Typography className={`${classes.activationFont}`}>
        Activation Email Sent !
      </Typography>
      <Button
        onClick={(e) => {
          setIsRegistering(false);
          signUpSuccess(false);
        }}
        className={`${classes.signIn}`}
        variant="contained"
      >
        Sign In
      </Button>
    </div>
  );
}
