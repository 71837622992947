import Axios from "axios";
import { HOST } from "../config";

async function UserAssetService() {
  const UserAssetResponse = await Axios.get(`${HOST}/v2/assets`);
  const { data, status, error } = UserAssetResponse;
  console.log({ data, status, error });
  return data;
}
export { UserAssetService };
