import { createStore, StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import AdminPage from "./layouts/AdminPage";
import LoginPage from "./layouts/LoginPage";
import authCheckerMiddleware from "./store/authChecker";
import storeModel from "./store/index";

const store = createStore(storeModel, {
  middleware: [authCheckerMiddleware],
});

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/admin/login" component={LoginPage} />
        <Route path="/admin" component={AdminPage} />
        <Redirect from="/" to="/admin" />
      </Switch>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById("root")
);
