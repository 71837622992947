import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import useUpdatePassword from "./hooks/useUpdatePassword";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  updatePass: {
    color: "#656766",
  },
  buttonStyle: {
    margin: "2em",
    color: "#fff",
  },
  submitButton: {
    margin: "2em",
    backgroundColor: "rgb(227, 83, 31)",
    color: "#fff",
  },
}));

export default function UpdatePassword() {
  const {
    username,
    invalidCode,
    bindPassword,
    bindCode,
    handleClickShowPassword,
    handleSubmit,
    showPassword,
    setForgotPassword,
  } = useUpdatePassword();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" className={`${classes.updatePass}`}>
        Update your Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          size="small"
          name="email"
          value={username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="New Password"
          size="small"
          name="password"
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          {...bindPassword}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Code"
          size="small"
          name="code"
          error={invalidCode}
          {...bindCode}
        />
        {invalidCode ? (
          <FormHelperText error>
            <svg
              aria-hidden="true"
              fill="currentColor"
              focusable="false"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
            </svg>{" "}
            Invalid code
          </FormHelperText>
        ) : null}

        <FormHelperText>
          Please enter the code that was sent to your email.{" "}
        </FormHelperText>
        <div className={`${classes.flexContainer}`}>
          <Button
            className={`${classes.buttonStyle}`}
            variant="contained"
            onClick={(e) => {
              setForgotPassword(false);
            }}
          >
            Back
          </Button>
          <Button
            type="submit"
            className={`${classes.submitButton}`}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
