import { makeStyles, Tooltip } from "@material-ui/core";
import React, { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "center",
  },
  copySuccess: {
    position: "absolute",
    top: 22,
    fontSize: ".7em",
  },
  copyInput: {
    border: "none",
    textAlign: "center",
    display: "inline",
    backgroundColor: "inherit",
    fontSize: "1em",
    cursor: "pointer",
    flexWrap: "wrap",
  },
}));

export default function CopyExample({ value, id }) {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const classes = useStyles();

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 3000);
  }

  return (
    <div className={`${classes.flexContainer}`}>
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <div className={`${classes.copySuccess}`}>{copySuccess}</div>
        )
      }
      <Tooltip title="Copy" aria-label="copy">
        <input
          id={id}
          onClick={copyToClipboard}
          ref={textAreaRef}
          type="text"
          value={value}
          size={value.length > 30 ? 42 : 26}
          className={`${classes.copyInput}`}
          readOnly
        />
      </Tooltip>
    </div>
  );
}
