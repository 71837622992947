import { action, thunk } from "easy-peasy";
import moment from "moment-timezone";
import { LoginService, ResetCodeService, SignUpService } from "../api";
import { UpdatePasswordService } from "../api/ForgotPasswordService";

const login = {
  isInvalidForm: false,
  isRegistering: false,
  username: "",
  password: "",
  token: null,
  idleTimer: "",
  user_type: "admin",
  isSignedUp: false,
  isLoading: false,
  invalidCode: false,
  snackBarMsg: { bool: false, message: "", variant: "success" },
  setSnackBarMsg: action((state, { bool, message, variant }) => {
    state.snackBarMsg = { bool, message, variant };
  }),
  setInvalidCode: action((state, bool) => {
    state.invalidCode = bool;
  }),
  getUpdatePassword: thunk(async (actions, { email, password, code }) => {
    actions.setIsLoading(true);
    try {
      const updatePasswordRes = await UpdatePasswordService({
        email,
        password,
        code,
      });
      console.log({ updatePasswordRes });
      actions.setResetCode(false);
      actions.setForgotPassword(false);
      actions.setInvalidCode(false);
      actions.setIsLoading(false);
      actions.setSnackBarMsg({
        bool: true,
        message: "Successfully Updated Password",
        variant: "success",
      });
    } catch (err) {
      console.log({ err });
      actions.setIsLoading(false);
      actions.setInvalidCode(true);
    }
  }),
  invalidResetEmail: false,
  setInvalidResetEmail: action((state, bool) => {
    state.invalidResetEmail = bool;
  }),
  forgotPassword: false,
  setForgotPassword: action((state, bool) => {
    state.forgotPassword = bool;
  }),
  sentResetCode: false,
  setResetCode: action((state, bool) => {
    state.sentResetCode = bool;
  }),
  sendResetCode: thunk(async (actions, { email }) => {
    actions.setIsLoading(true);
    const resetCodeRes = await ResetCodeService({ email });
    if (resetCodeRes === 500) {
      actions.setInvalidResetEmail(true);
      actions.setIsLoading(false);
    } else {
      actions.setUsername(email);
      actions.setResetCode(true);
      actions.setIsLoading(false);
    }
  }),
  setInvalidForm: action((state, bool) => {
    state.isInvalidForm = bool;
  }),
  setIsLoading: action((state, bool) => {
    state.isLoading = bool;
  }),
  setIsRegistering: action((state, bool) => {
    state.isRegistering = bool;
  }),
  signUp: thunk(async function (actions, { signUpObj }) {
    actions.setIsLoading(true);
    const signUpData = await SignUpService({ signUpObj });
    // console.log(signUpData);
    if (signUpData === 500) {
      actions.setSignUpErr(true);
      actions.setIsLoading(false);
    } else {
      actions.signUpSuccess(true);
      actions.setSignUpErr(false);
      actions.setIsLoading(false);
    }
  }),
  signUpSuccess: action((state, bool) => {
    state.isSignedUp = bool;
  }),
  signUpErr: false,
  setSignUpErr: action((state, bool) => {
    state.signUpErr = bool;
  }),
  authenticate: thunk(async function (actions, data) {
    // console.log(HOST);
    try {
      const { username, password } = data;

      actions.setIsLoading(true);
      const loginData = await LoginService({ username, password });
      if (Object.values(loginData).length > 0) {
        actions.setInvalidForm(false);
        actions.setUsername(username);
        actions.setToken(loginData);
        actions.setIsLoading(false);
      } else {
        actions.setIsLoading(false);
        actions.setInvalidForm(true);
      }
    } catch (err) {
      // console.log(err)
      actions.setIsLoading(false);
      actions.setInvalidForm(true);
    }
  }),

  setToken: action((state, auth) => {
    if (!auth) {
      state.token = null;
    } else {
      localStorage.setItem("token", JSON.stringify(auth));
      const { token } = auth;
      state.token = token;
    }
  }),
  checkIfTokenExists: thunk(async function (actions) {
    console.log("checkIfTokenExists");
    const auth = JSON.parse(localStorage.getItem("token"));

    if (auth) {
      const { expire } = auth;
      const expiration = moment(expire).utc();

      if (expiration.isBefore(moment())) {
        actions.setToken(null);
      } else {
        actions.setToken(auth);
      }
    }
  }),
  setUsername: action((state, newUser) => {
    state.username = newUser;
  }),
  setPassword: action((state, newPass) => {
    state.password = newPass;
  }),

  logOut: action((state) => {
    localStorage.clear("token");
    state.token = null;
    state.username = "";
    state.password = "";
  }),
};

export default login;
