import moment from "moment-timezone";

const AuthChecker = (store) => (next) => (action) => {
  if (action.error) {
    const auth = localStorage.getItem("token") || null;

    if (auth === null) {
      return;
    }
    const { expire } = localStorage.getItem("token");
    const expiration = moment(expire).utc();

    if (expiration.isBefore(moment())) {
      // console.log("token Expired");
      store.dispatch({ type: "@action.login.setToken", payload: null });
    } else {
      store.dispatch({
        type: "@action.login.setToken",
        payload: JSON.parse(auth),
      });
    }
  }
  next(action);
};

export default AuthChecker;
