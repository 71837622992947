import { makeStyles } from "@material-ui/core";
import { useStoreState } from "easy-peasy";
import React from "react";
import { CSSTransition } from "react-transition-group";
import ResetCode from "./ResetCode";
import UpdatePassword from "./UpdatePassword";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ResetPassword() {
  const sentResetCode = useStoreState((state) => state.login.sentResetCode);
  const classes = useStyles();
  return (
    <div className={`${classes.flexContainer}`}>
      {!sentResetCode ? (
        <CSSTransition
          in={!sentResetCode}
          key={"resetcode"}
          appear={true}
          timeout={300}
          classNames="fade"
        >
          <ResetCode />
        </CSSTransition>
      ) : (
        <CSSTransition
          in={sentResetCode}
          key={"updatepassword"}
          appear={true}
          timeout={300}
          classNames="fade"
        >
          <UpdatePassword />
        </CSSTransition>
      )}
    </div>
  );
}
