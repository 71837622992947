import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";

export default function useDeviceTotals() {
  const totalDevices = useStoreState((state) => state.devices.totalDevices);
  const connectedDevices = useStoreState(
    (state) => state.devices.connectedDevices
  );
  const isLoading = useStoreState((state) => state.devices.isLoading);
  const totalMessages = useStoreState((state) => state.devices.totalMessages);
  const getUserInfo = useStoreActions((state) => state.devices.getUserInfo);

  useEffect(() => {
    getUserInfo({ authInfo: localStorage.getItem("token") });
    //eslint-disable-next-line
  }, []);
  return {
    totalDevices,
    connectedDevices,
    totalMessages,
    isLoading,
  };
}
