import Axios from "axios";
import { HOST } from "../config";

async function ResetCodeService({ email }) {
  const ResetCodeResponse = await Axios.get(
    `${HOST}/v2/users/reset-password`,
    {
      params: { email },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.status;
    });
  const resetCodeRes = ResetCodeResponse;
  return resetCodeRes;
}
async function UpdatePasswordService({ email, password, code }) {
  // console.log({ email, password, code });
  const updatePasswordRes = await Axios.post(
    `${HOST}/v2/users/update-password`,
    {
      code,
      email,
      password,
    }
  );
  const updateRes = updatePasswordRes.data;
  return updateRes;
}
export { ResetCodeService, UpdatePasswordService };

