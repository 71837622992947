import { makeStyles } from "@material-ui/core";
import { useStoreState } from "easy-peasy";
import React from "react";
import { CSSTransition } from "react-transition-group";
import ResetPassword from "../ResetPassword";
import Activation from "./Activation";
import LoginSnackMsg from "./LoginSnackMsg";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    // backgroundColor: "#656766",
    color: "#fff",
  },
}));

export default function Login() {
  const classes = useStyles();
  const isRegistering = useStoreState((state) => state.login.isRegistering);
  const forgotPassword = useStoreState((state) => state.login.forgotPassword);
  const isSignedUp = useStoreState((state) => state.login.isSignedUp);

  return (
    <div className={classes.paper}>
      <LoginSnackMsg />
      {!isRegistering ? (
        forgotPassword ? (
          <CSSTransition
            in={forgotPassword}
            key={"resetpassword"}
            appear={true}
            timeout={300}
            classNames="fade"
          >
            <ResetPassword />
          </CSSTransition>
        ) : (
          <CSSTransition
            in={!isRegistering}
            key={"signin"}
            appear={true}
            timeout={300}
            classNames="fade"
          >
            <SignIn />
          </CSSTransition>
        )
      ) : (
        <>
          {isSignedUp ? (
            <CSSTransition
              in={isSignedUp}
              key={"activation"}
              appear={true}
              timeout={300}
              classNames="fade"
            >
              <Activation />
            </CSSTransition>
          ) : (
            <CSSTransition
              in={!isSignedUp}
              key={"signup"}
              appear={true}
              timeout={300}
              classNames="fade"
            >
              <SignUp />
            </CSSTransition>
          )}
        </>
      )}
    </div>
  );
}
