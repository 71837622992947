import {
  Button,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useInput } from "../../hooks/useInput";
import useResetCode from "./hooks/useResetCode";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  forgotPass: {
    color: "#656766",
  },
  buttonStyle: {
    margin: "2em",
    color: "#fff",
  },
  submitButton: {
    margin: "2em",
    backgroundColor: "rgb(227, 83, 31)",
    color: "#fff",
  },
}));

export default function ResetCode() {
  const { handleSubmit, invalidResetEmail, setForgotPassword } = useResetCode();
  const { bind: bindEmail } = useInput("");
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={`${classes.forgotPass}`}>
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          size="small"
          name="email"
          {...bindEmail}
          error={invalidResetEmail}
          autoComplete="email"
          autoFocus
        />
        {invalidResetEmail ? (
          <FormHelperText error>
            <svg
              aria-hidden="true"
              fill="currentColor"
              focusable="false"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
            </svg>{" "}
            Invalid email
          </FormHelperText>
        ) : null}
        <FormHelperText>
          Please enter your email address that you signed up with.{" "}
        </FormHelperText>
        <div className={`${classes.flexContainer}`}>
          <Button
            onClick={(e) => {
              setForgotPassword(false);
            }}
            className={`${classes.buttonStyle}`}
            variant="contained"
          >
            Back
          </Button>
          <Button
            type="submit"
            className={`${classes.submitButton}`}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
