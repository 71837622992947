import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import DeviceConsole from "./components/DeviceConsole/index";

const routes = [
  {
    path: "/deviceconsole",
    name: "Device Console",
    icon: ImportantDevicesIcon,
    component: DeviceConsole,
    layout: "/admin",
  },
];

export default routes;
